/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { FC, Suspense, lazy, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { CardHorizontalMini, IconBlock, Heading, TabComponent, Types, canUseDOM } from '@marriott/mi-ui-library';
import { StyledCobrandContainer } from './CobrandContainer.styles';
import { CobrandContainerProps } from './CobrandContainer.types';
import { PointsEarned } from '../PointsEarned';
import { BANK_LANDING_TAB_LIST as allTabList } from '../../modules/constants/constants';
import { StickyCard } from '../StickyCard';
import { equalizeBankLandingCards, removeExtraHeights, getColumnClass, AEMReactCompMap } from '../../modules/utils';
import { CardOverview } from '../CardOverview';
import { useStore } from '../../modules/store/cobrandStore';

export const CobrandContainerConfig = {
  emptyLabel: 'CobrandContainer',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/cobrandcontainer`,
};

export const CobrandContainerComponent: FC<CobrandContainerProps> = (props: CobrandContainerProps) => {
  const {
    header,
    columnLayout,
    cqItems,
    containerId,
    pagePath,
    itemPath,
    totalNumberOfChildComponents,
    appliedCssClassNames,
    isAuthorMode,
    enableClickTrack,
    isStickyCardEnabled,
    variant = 'default',
    cardCount = 2,
    description,
    bgImage = '',
    styleclass,
    isChaseButton,
    isAmexButton,
    isTertiaryLinksVisible,
    isImagehidden,
    isPointDescriptionVisible,
    isBonusPointsLabelVisible,
  } = props;

  const mapper = AEMReactCompMap(props?.allowedComponents);
  const containerRef = useRef<HTMLDivElement>(null);
  const stickyComponentRef = useRef<HTMLDivElement>(null);
  const cardOverviewRef = useRef<HTMLDivElement>(null);
  const { setCobrandData } = useStore(store => store);

  useEffect(() => {
    equalizeBankLandingCards(containerRef);
    if (variant === 'default-layout') {
      replaceHorizontalMiniClass();
    }

    window.addEventListener('resize', screenResizeHandler);
    return () => {
      window.removeEventListener('resize', screenResizeHandler);
    };
  }, []);

  const replaceHorizontalMiniClass = () => {
    if (canUseDOM) {
      const cardHorizontalMiniList = containerRef?.current?.querySelectorAll('.miniCardContent .t-font-alt-s');
      if (cardHorizontalMiniList?.length) {
        cardHorizontalMiniList.forEach(cardHorizontalEl => {
          cardHorizontalEl.classList.remove('t-font-alt-s');
          cardHorizontalEl.classList.add('t-font-alt-xs');
        });
      }
    }
  };

  const screenResizeHandler = function () {
    if (variant === 'default-layout') {
      replaceHorizontalMiniClass();
    } else if (variant === 'blp') {
      removeExtraHeights(containerRef);
      equalizeBankLandingCards(containerRef);
    }
  };

  //Method to get card type count used for tab component
  const getCardCount = (jsonData: any) => {
    const allCardTypes: any = {
      personal: 0,
      business: 0,
    };
    if (columnLayout && columnLayout.toString() === '3') {
      if (jsonData && Object.keys(jsonData) && Object.keys(jsonData).length) {
        Object.keys(jsonData)?.map((cardName: string) => {
          // eslint-disable-next-line no-prototype-builtins
          if (jsonData.hasOwnProperty(cardName)) {
            const card = jsonData[cardName];
            const cardItems = card[':items'];
            for (const itemKey in cardItems) {
              // eslint-disable-next-line no-prototype-builtins
              if (cardItems?.hasOwnProperty(itemKey)) {
                const item = cardItems[itemKey];
                if (itemKey.includes('cardoverview') && item.cardType) {
                  if (item.cardType) {
                    allCardTypes[item.cardType]++;
                  }
                }
              }
            }
          }
        });
      }
    }
    return allCardTypes;
  };

  //Below code is used as props for Tab component
  const allCardTypes = getCardCount(cqItems);

  //Method returns list of Tabs to be displayed to be displayed
  const getTabList = () => {
    return allTabList.map(tabItem => {
      const tab = {
        ...tabItem,
        ...{ tabSubtitle: `${allCardTypes[tabItem.tabValue]} Card${tabItem.tabValue === '1' ? '' : 's'}` },
      };
      return tab;
    });
  };

  //Method returns list of Card to be displayed for sticky component
  const getCardDetails = (jsonData: any) => {
    const cardDetails: any = [];
    let cardCount = 0;
    if (isStickyCardEnabled && jsonData && Object.keys(jsonData) && Object.keys(jsonData).length) {
      Object.keys(jsonData)
        ?.reverse()
        .map((cardName: string) => {
          // eslint-disable-next-line no-prototype-builtins
          if (jsonData.hasOwnProperty(cardName)) {
            const card = jsonData[cardName];
            const cardItems = card[':items'];
            for (const itemKey in cardItems) {
              // eslint-disable-next-line no-prototype-builtins
              if (cardItems?.hasOwnProperty(itemKey)) {
                const item = cardItems[itemKey];
                if (itemKey.includes('cardoverview') && cardCount < columnLayout) {
                  const {
                    header,
                    imagePath,
                    altText,
                    linkText,
                    linkUrl,
                    openInaNewTab,
                    additionalTrackingVariables,
                    trackingDescription,
                    impressionTrack,
                    trackingContentPosition,
                    atCCeVar48,
                    clickTrack,
                    merchandisingCategory,
                    impressionCount,
                    trackingTag,
                    trackingOfferType,
                    cardType,
                    //cardName,
                    tertiaryLinks,
                    pointSectionDescription,
                  } = item;
                  cardDetails.push({
                    imagePath,
                    altText,
                    header,
                    linkText,
                    linkUrl,
                    openInaNewTab,
                    additionalTrackingVariables,
                    trackingDescription,
                    impressionTrack,
                    trackingContentPosition,
                    atCCeVar48,
                    clickTrack,
                    merchandisingCategory,
                    impressionCount,
                    trackingTag,
                    trackingOfferType,
                    columnCount: `${columnLayout}`,
                    cardType,
                    tertiaryLinks,
                    pointSectionDescription,
                  });
                  cardCount++;
                }
              }
            }
          }
        });
    }

    return cardDetails;
  };

  //Method for returning Responsive Grid for showing parsys in Author mode
  const cardContainer = (index: number) => {
    const cardType = index + 1 > 2 ? 'business' : 'personal';
    return (
      <div className={clsx(variant === 'continent' ? '' : getColumnClass(`${columnLayout}-column`, cardType))}>
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          customClassName={`p-0 m-0`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${totalNumberOfChildComponents?.[index]}`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning Responsive Grid for showing parsys in Author mode
  const cardContainerDefault = (index: number) => {
    return (
      <div className={`col-12 col-md-6 col-xl-4`}>
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          customClassName={`p-0 m-0 py-2`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${totalNumberOfChildComponents?.[index]}`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL
  const cardComponent = (cardName: string, jsonData: any, index: number): JSX.Element | null => {
    const returnable: JSX.Element[] = [];
    const cardType = index + 1 > 2 ? 'business' : 'personal';

    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      let rowCount = 1;

      card[':itemsOrder'].forEach((itemKey: string) => {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          if (itemKey.includes('cardoverview')) {
            returnable.push(
              <CardOverview
                {...item}
                column={`${columnLayout}`}
                customClass={clsx(`overview-${rowCount++}`)}
                linkUrl={item.linkUrl}
                key={itemKey}
              />
            );
          } else if (itemKey.includes('points_section')) {
            let pointsClass = 'spend';
            if (item?.enablePointDetailsSection && item?.enablePointDetailsSection === 'true') {
              pointsClass = 'detail';
            }
            returnable.push(
              <PointsEarned
                column={`${columnLayout}`}
                {...item}
                customClass={clsx(
                  pointsClass === 'spend' ? (rowCount > 1 ? 'mt-5' : 'pt-lg-3') : '',
                  `${pointsClass}-${rowCount++}`
                )}
                key={itemKey}
                isBonusPointsLabelVisible={isBonusPointsLabelVisible}
              />
            );
          } else if (itemKey.includes('cardhorizontalmini')) {
            returnable.push(
              <div className={'py-2 pr-3 d-flex'} key={itemKey}>
                <CardHorizontalMini {...item} />
              </div>
            );
          } else {
            const itemType = item[':type']?.split('/').pop();
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );
            returnable.push(
              <Suspense key={itemKey} fallback={<></>}>
                <Component {...item} />
              </Suspense>
            );
          }
        }
      });

      return (
        <div
          className={clsx(
            variant === 'blp'
              ? `${getColumnClass(`${columnLayout}-column`, cardType)} p-0 m-0 px-2`
              : 'col-12 col-md-6 col-lg-4 d-flex flex-column'
          )}
        >
          {returnable}
        </div>
      );
    }
    return null;
  };

  const cardComponentDefault = (cardName: string, jsonData: any, index: number) => {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems = card[':items'];
      for (const itemKey in cardItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (cardItems?.hasOwnProperty(itemKey)) {
          const item = cardItems[itemKey];
          if (itemKey.includes('cardhorizontalmini')) {
            return (
              <div className={'col-12 col-md-6 col-xl-4 py-2 pr-2'} key={index}>
                <CardHorizontalMini {...item} styleclass={`${item.styleclass} cobrand-container__tile`} />
              </div>
            );
          } else if (itemKey.includes('iconblock')) {
            return (
              <div className={'col-12 col-md-6 col-xl-4 py-2'} key={index}>
                <IconBlock {...item} styleclass={`standard t-background-color cobrand-container__tile`} />
              </div>
            );
          } else if (itemKey.includes('points_section')) {
            return <PointsEarned {...item} key={index} isBonusPointsLabelVisible={isBonusPointsLabelVisible} />;
          } else {
            const itemType = item[':type']?.split('/').pop();
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );
            return (
              <Suspense key={index} fallback={<></>}>
                <Component {...item} />
              </Suspense>
            );
          }
        }
      }
    }
    return null;
  };

  return (
    <StyledCobrandContainer
      className={clsx(
        'container',
        'cobrand-container',
        'py-3 mt-md-3 mt-1',
        `${variant === 'blp' ? 'px-0' : ''}`,
        `${variant === 'default-layout' ? 'p-0 m-0' : ''}`,
        `${bgImage && variant === 'continent' ? 'cobrand-container--image' : ''}`,
        `${appliedCssClassNames ? appliedCssClassNames : ''}`,
        styleclass
      )}
      data-component-name="o-cobrand-cobrandcontainer"
      data-testid="cobrandcontainer"
      ref={containerRef}
      bgImage={variant === 'continent' ? bgImage : ''}
      id={containerId}
    >
      {header && (
        <Heading
          titleText={header}
          variation={Types.headingType.title}
          fontSize={variant === 'blp' ? Types.size.small : Types.size.medium}
          customClass={clsx(
            `${variant === 'blp' ? 'text-md-left' : ''} ${
              variant === 'default-layout' ? '' : 'mt-lg-4 mt-md-2 mt-0'
            } text-center mb-md-4 mb-lg-5`
          )}
        />
      )}
      {description && (
        <Heading
          titleText={description}
          variation={Types.headingType.subtitle}
          fontSize={Types.size.large}
          customClass={clsx(`text-center mb-5 pb-3`)}
        />
      )}
      {allCardTypes && allCardTypes.personal && allCardTypes.personal > 0 ? (
        <div className={clsx('cobrand-container-tabs', 'd-block d-md-none')}>
          <TabComponent
            tabList={getTabList()}
            setSelectedTab={(activeTabValue: string) => {
              setCobrandData({ activeTabValue, selectedCards: [] });
            }}
            customClass=""
          />
        </div>
      ) : null}
      {isStickyCardEnabled ? (
        <StickyCard
          cardDetails={getCardDetails(cqItems)}
          ref={stickyComponentRef}
          cardOverviewRef={cardOverviewRef}
          isChaseButton={isChaseButton}
          isAmexButton={isAmexButton}
          isTertiaryLinksVisible={isTertiaryLinksVisible}
          isImagehidden={isImagehidden}
          isPointDescriptionVisible={isPointDescriptionVisible}
        />
      ) : null}
      <div
        className={clsx(
          variant === 'blp'
            ? `row justify-content-around pb-lg-5 ${
                columnLayout && columnLayout.toString() === '2' ? 'two-column' : 'three-column'
              }`
            : 'row d-flex'
        )}
        data-is-click-track={enableClickTrack}
        ref={cardOverviewRef}
      >
        {variant === 'blp' && isAuthorMode && Array.from({ length: columnLayout }, (_, i) => cardContainer(i))}
        {variant === 'blp' &&
          !isAuthorMode &&
          totalNumberOfChildComponents?.map((cardName: string, index: number) =>
            cardComponent(cardName, cqItems, index)
          )}
        {variant === 'continent' &&
          isAuthorMode &&
          Array.from({ length: totalNumberOfChildComponents.length }, (_, i) => cardContainerDefault(i))}
        {variant === 'continent' &&
          !isAuthorMode &&
          totalNumberOfChildComponents?.map((cardName: string, index: number) =>
            cardComponent(cardName, cqItems, index)
          )}
        {variant === 'default-layout' &&
          isAuthorMode &&
          Array.from({ length: cardCount }, (_, i) => cardContainerDefault(i))}
        {variant === 'default-layout' &&
          !isAuthorMode &&
          totalNumberOfChildComponents?.map((cardName: string, index: number) =>
            cardComponentDefault(cardName, cqItems, index)
          )}
      </div>
    </StyledCobrandContainer>
  );
};

export const CobrandContainerEditableComponent = (props: CobrandContainerProps) => {
  return (
    <EditableComponent config={CobrandContainerConfig} {...props}>
      <CobrandContainerComponent {...props} />
    </EditableComponent>
  );
};

export const CobrandContainer = (props: any) => {
  const { model } = props;
  return (
    <div
      className={clsx(
        [model?.appliedCssClassNames?.includes('fullbleed') ? 'm-container-fullbleed' : ''],
        [model?.appliedCssClassNames?.includes('alternate') ? 'alternate' : '']
      )}
      data-testid="card-container"
    >
      <div className={clsx('container', { 'p-0': !model?.appliedCssClassNames?.includes('fullbleed') })}>
        <CobrandContainerEditableComponent {...props} />
      </div>
    </div>
  );
};
