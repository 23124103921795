// Imports for external libraries go here.
import { FC } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { RichText, Icon, Image, Text, size, tags, Button } from '@marriott/mi-ui-library';

import { DataCellProps } from './DataCellComponent.types';
import { StyledDataCell } from './DataCellComponent.styles';
import { EliteStatus, IconType, DataType } from '../../modules/utils';

export const DataCellConfig = {
  emptyLabel: 'DataCell',
  isEmpty: () => true,
  resourceType: `/components/content/datacell`,
};

// Use named rather than default exports.
export const DataCellComp: FC<DataCellProps> = (props: DataCellProps) => {
  const {
    styleclass,
    categoryIconPath,
    imagePath,
    altText,
    editAltTextCheck,
    editedImgAltText,
    isAuthorMode,
    categoryId,
    groupid,
    eliteStatus,
    primaryCTAText,
    primaryCtaURL,
    tertiaryLinks,
    openInNewTabPrimary,
    dataType,
    iconType,
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack,
    impressionTrack,
    impressionCount,
  } = props;
  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };

  const linkType = openInNewTabPrimary ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;
  const headerText = props.cardSummary || props.categoryHeading;
  const isHeader = props.type === DataType.Header ? true : false;

  return (
    <StyledDataCell className={clsx(styleclass)}>
      <div className={clsx('d-flex flex-column cell-wrapper', isAuthorMode ? 'cell-border mb-2' : '')}>
        {isHeader && (
          <div className={clsx('py-3 px-4 badge', EliteStatus[eliteStatus as keyof typeof EliteStatus] + '-status')}>
            <Text
              fontSize={size.small}
              element={tags.paragraph}
              copyText={eliteStatus}
              customClass={'t-font-inverse-s'}
            ></Text>
          </div>
        )}
        <div className={clsx('p-3', props.categoryHeading ? 'justify-content-flex-start' : 'd-flex flex-grow-1')}>
          {isAuthorMode && categoryId && (
            <div>
              <div> Category ID: {categoryId}</div>
              <div> Group ID: {groupid}</div>
              {imagePath && headerText && headerText.length && (
                <div className="d-flex flex-column flex-md-row">
                  <Image
                    defaultImageURL={imagePath}
                    altText={editAltTextCheck ? editedImgAltText : altText}
                    customClass={clsx('card-image pr-3')}
                  />
                  <RichText componentId={`data-cell`} text={headerText} />
                </div>
              )}
              {!imagePath && headerText && headerText.length && (
                <div className="d-flex ">
                  {categoryIconPath && categoryIconPath.length && (
                    <div className={clsx('mr-2')}>
                      <Icon iconClass={`${categoryIconPath} icon-l`} />
                    </div>
                  )}
                  <RichText componentId={`data-cell`} text={headerText} />
                </div>
              )}
            </div>
          )}
          {!isAuthorMode && (
            <div className={clsx(headerText ? 'd-flex flex-grow-1' : '')}>
              {isHeader && headerText && headerText.length && tertiaryLinks && (
                <div className="d-flex flex-column justify-content-between flex-grow-1">
                  <div className="d-flex flex-column flex-lg-row">
                    <Image
                      defaultImageURL={imagePath}
                      altText={editAltTextCheck ? editedImgAltText : altText}
                      customClass={clsx('card-image pr-3')}
                    />
                    <RichText customClass={clsx('mt-3 mt-lg-0')} componentId={`data-cell`} text={headerText} />
                  </div>
                  <div>
                    <RichText
                      componentId={`data-cell`}
                      text={tertiaryLinks}
                      customClass={clsx('my-3 tertiary-links')}
                    />
                    {primaryCTAText && (
                      <Button
                        buttonCopy={primaryCTAText}
                        href={primaryCtaURL}
                        isLink={true}
                        target={openInNewTabPrimary === 'true' ? '_blank' : '_self'}
                        className={' m-button-m'}
                        trackingProperties={trackingProperties}
                        linkType={linkType}
                        custom_click_track_value={clickTrackValue}
                        linkAriaLabelOpenNewWindow={openInNewTabPrimary ? 'Open in new tab' : ''}
                      ></Button>
                    )}
                  </div>
                </div>
              )}
              {!isHeader && headerText && headerText.length && (
                <div className="d-flex">
                  {categoryIconPath && categoryIconPath.length && (
                    <div className={clsx('mr-2')}>
                      <Icon iconClass={`${categoryIconPath} icon-l`} />
                    </div>
                  )}
                  <RichText componentId={`data-cell`} text={headerText} />
                </div>
              )}
            </div>
          )}
          {dataType === DataType.Icons && iconType && iconType.length && (
            <div
              className={clsx('icon-check-' + IconType[iconType as keyof typeof IconType], 'icon-wrapper text-center')}
            >
              <Icon iconClass={'icon-check icon-inverse'} />
            </div>
          )}
          {dataType === DataType.Emtpy && <div className={'text-left'}> ___</div>}
        </div>
      </div>
    </StyledDataCell>
  );
};

export const DataCell = (props: DataCellProps) => {
  return (
    <EditableComponent config={DataCellConfig} {...props}>
      <DataCellComp {...props} />
    </EditableComponent>
  );
};
