import React, { forwardRef, useEffect } from 'react';
import clsx from 'clsx';

import { canUseDOM } from '@marriott/mi-ui-library';
import { shouldCardDisplay } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';
import { StickyCardSection } from './StickyCardSection';
import { StickyCardSectionProps } from './StickyCardSection/StickyCardSection.types';
import { StyledStickyCard } from './StickyCard.styles';
import { StickyCardProps } from './StickyCard.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StickyCard = forwardRef((props: StickyCardProps, ref?: any) => {
  const {
    cardDetails,
    cardOverviewRef,
    isChaseButton,
    isAmexButton,
    isTertiaryLinksVisible,
    isImagehidden,
    isPointDescriptionVisible,
  } = props;
  const { cobrandData } = useStore(store => store);

  //Method to return column width helper for each column
  const getColumnClass = (column: string, cardType: string) => {
    let returnable = 'col-12';
    if (column === '2') {
      returnable = 'col-6 col-md-5';
    } else if (column === '3') {
      returnable = shouldCardDisplay(cardType, cobrandData.activeTabValue)
        ? 'col-6 col-md-4'
        : 'col-6 col-md-4 d-none d-md-block';
    }
    return returnable;
  };

  useEffect(() => {
    const handleScroll = () => {
      let distanceAtBottom = 0;
      if (canUseDOM) {
        if (cardOverviewRef && ref) {
          distanceAtBottom = cardOverviewRef.current?.getBoundingClientRect().bottom;
          const stickyComponentThreshhold = 100;
          if (distanceAtBottom <= stickyComponentThreshhold) {
            ref.current?.classList.remove('d-none');
          } else {
            ref.current?.classList.add('d-none');
          }
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledStickyCard data-testid="StickyCard" data-component-name="o-cobrand-StickyCard">
      {cardDetails?.length > 0 && (
        <div
          className={clsx(
            'sticky-card-container m-container-anchored-top p-0',
            'pb-md-4 pb-2 pt-1 my-0 mx-auto',
            'd-none'
          )}
          data-testid="stickycardcontainer"
          ref={ref}
        >
          <div className="container">
            <div className={clsx('row', 'justify-content-around', 'mt-2 mt-md-4')}>
              {cardDetails?.map((card: StickyCardSectionProps, key: number) => (
                <div
                  className={clsx(card.columnCount ? getColumnClass(card.columnCount, card.cardType) : '')}
                  data-cardtype={card.cardType}
                  key={key}
                >
                  <StickyCardSection
                    {...card}
                    cardTitle={card.header}
                    tertiaryLinks={card?.tertiaryLinks}
                    isChaseButton={isChaseButton || false}
                    isAmexButton={isAmexButton || false}
                    isTertiaryLinksVisible={isTertiaryLinksVisible || false}
                    isImagehidden={isImagehidden || false}
                    isPointDescriptionVisible={isPointDescriptionVisible || false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </StyledStickyCard>
  );
});
