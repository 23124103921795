/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { useCheckBreakpoint, Button, Accordion } from '@marriott/mi-ui-library';
import { CardComparisionProps, dataJson, categoryJson } from './CardComparision.types';
import { StyledCardComparision } from './CardComparision.styles';
import { DataCell } from '../../molecules/DataCellComponent';
export const CardComparisionConfig = {
  emptyLabel: 'CardComparision',
  isEmpty: () => true,
  resourceType: `/components/content/cardcomparision`,
};

// Use named rather than default exports.
export const CardComparisionComp: FC<CardComparisionProps> = (props: CardComparisionProps) => {
  const selectedCategories = props?.idValues?.includes(',') ? props?.idValues?.split(',') : [];
  const { linkText, linkUrl } = props;
  const isViewportM = useCheckBreakpoint('viewportL');
  const [dataJson, setdataJson] = useState<dataJson[]>([]);
  const [categoryJson, setcategoryJson] = useState<categoryJson[]>([]);
  const [selectedCards, setselectedCards] = useState<string[]>([]);
  const [categoryGroups, setcategoryGroups] = useState<{ id: string; value: string; count: number }[]>([]);
  const [isFixed, setIsFixed] = useState(false);

  const setCardArray = (cardArray: any) => {
    const dataArray: any[] = [];
    for (const cardkey in cardArray) {
      if (cardkey !== 'categories') {
        const eachCardData = Object.values(cardArray[cardkey]);
        eachCardData.map((data: any) => {
          data.cardId = cardkey;
          return data;
        });
        dataArray.push(...eachCardData);
      }
    }
    return dataArray;
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cardIdFromUrl: string[] = [];
    for (const param of urlParams.keys()) {
      if (param.includes('cardtype') && urlParams?.get(param)) {
        cardIdFromUrl.push(urlParams?.get(param)!);
      }
    }
    setselectedCards(cardIdFromUrl!);
    const categoryData: any[] = props?.pageItems?.categories ? Object.values(props?.pageItems?.categories) : [];
    const cardData: dataJson[] = setCardArray(props?.pageItems);
    const filteredCategory = selectedCategories.length
      ? selectedCategories
      : categoryData.map(category => category.categoryId);
    const selectedCardsData = cardData?.filter((data: dataJson) => cardIdFromUrl?.includes(data?.cardId!));
    const categoriesWithEmptyEntries: string[] = [];
    //remove category entry with empty card data for all selected cards
    filteredCategory.forEach((element: any) => {
      const list = selectedCardsData?.filter((data: dataJson) => data.categoryId === element);
      if (list.every((item: any) => item.dataType === 'emptyField')) {
        categoriesWithEmptyEntries.push(element);
      }
    });
    const categoryWithEntries = categoryData.filter(
      (el: categoryJson) => !categoriesWithEmptyEntries.includes(el.categoryId)
    );

    //Filter selected category list from complete category json
    const filteredCategoryWithData =
      categoryWithEntries &&
      categoryWithEntries?.filter((category: categoryJson) => filteredCategory?.includes(category.categoryId));
    const sortedCategory = filteredCategoryWithData?.sort(function (a: categoryJson, b: categoryJson) {
      return a.categoryId.localeCompare(b.categoryId);
    });
    setcategoryJson(sortedCategory);
    const groupIds: { id: string; value: string; count: number }[] = [];
    filteredCategoryWithData.forEach((obj: categoryJson, i: number) => {
      if (!groupIds.some(item => item.id === obj.groupId && item.value === obj.groupValue)) {
        groupIds.push({ id: obj.groupId!, value: obj.groupValue!, count: i! });
      }
    });
    const sortedCategoryGroups = groupIds.sort(function (a, b) {
      return a.id.localeCompare(b.id);
    });
    setcategoryGroups(sortedCategoryGroups.filter(data => data.id !== 'Header'));

    //Filter the required data json to be displayed based on selected category list
    setdataJson(cardData?.filter((data: dataJson) => cardIdFromUrl?.includes(data?.cardId!)));
    // Set header row on the table fixed to top of the page on scroll
    const handleScroll = () => {
      let tableHeaderThreshold: any = 0;
      const tableElement = document.querySelector('.table-container');
      tableHeaderThreshold = tableElement?.getBoundingClientRect().top;
      setIsFixed(window.scrollY > tableHeaderThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getColumnClass = () => {
    return selectedCards.length === 3 ? 'col-4' : 'col-6';
  };
  const TableRow = (props: { category: categoryJson; dataList: dataJson[]; type?: string }) => {
    const category = props?.category;
    const dataList = props?.dataList;
    return (
      <div className={clsx('d-flex flex-wrap flex-column flex-md-row data-row col-12 px-0')}>
        <DataCell
          {...category}
          styleclass={clsx('data-cell category-cell col-12 col-md-4 col-lg-3 px-0')}
          groupid={category.groupId}
        />
        <div className="d-flex col-12 col-md-8 col-lg-9 p-0 data-section">
          {dataList.length &&
            dataList.map((data: dataJson) => {
              return (
                <DataCell
                  {...data}
                  styleclass={clsx('data-cell px-0', getColumnClass())}
                  type={props?.type}
                  groupid={category.groupId}
                />
              );
            })}
        </div>
      </div>
    );
  };
  //This method is to get card data to be displayed for each row based on category id
  const getDataList = (category: categoryJson) => {
    const list = dataJson && dataJson?.filter((data: dataJson) => data.categoryId === category.categoryId);
    return list.sort(
      (a: dataJson, b: dataJson) => selectedCards?.indexOf(a?.cardId!) - selectedCards?.indexOf(b.cardId!)
    );
  };
  const groupedCategories = (CategoryJsonList: categoryJson[], groupId: string) => {
    return (
      CategoryJsonList &&
      CategoryJsonList?.filter(
        (category: categoryJson) => category.groupId === groupId && category.groupId !== 'Header'
      )
    );
  };
  const headerRow = categoryJson && categoryJson?.filter((category: categoryJson) => category.groupId === 'Header');

  return (
    <StyledCardComparision>
      <div className={'col-12 p-0'}>
        <div className="d-flex justify-content-center justify-content-md-start">
          <Button isLink={true} href={linkUrl} className={'m-button-secondary-icon m-button-m my-5'} testId="backbtn">
            <span className="icon-back-arrow" />
            {linkText}
          </Button>
        </div>
        <div className="table-container col-12 p-0 mt-3 mb-5">
          {dataJson && dataJson.length ? (
            <div className="table-wrapper col-12 p-0">
              {headerRow.length &&
                headerRow.map((category: categoryJson) => {
                  const dataList = getDataList(category);
                  return (
                    <div className={`table-header align-items-center ${isFixed ? 'stickyHeader' : ''}`}>
                      <TableRow category={category} dataList={dataList} type="header" />
                    </div>
                  );
                })}
              {categoryGroups &&
                categoryGroups.length &&
                categoryGroups.map(group => {
                  const categoriesToBeShown = groupedCategories(categoryJson, group.id);
                  return (
                    <Accordion
                      id={group.id}
                      headerChildren={group.value}
                      styleclass="accordion-container"
                      isOpen={!isViewportM ? (group.count > 1 ? false : true) : true}
                    >
                      {categoriesToBeShown.length &&
                        categoriesToBeShown.map((category: categoryJson) => {
                          const dataList = getDataList(category);
                          return (
                            <div>
                              <TableRow category={category} dataList={dataList} />
                            </div>
                          );
                        })}
                    </Accordion>
                  );
                })}
            </div>
          ) : (
            <div className="skeleton-loader ">
              <Skeleton height="2.5rem" width={`col-12`} />
            </div>
          )}
        </div>
        <div className={'d-flex justify-content-center'}>
          <Button isLink={true} href="#" className={'my-5'} testId="buttonToTop">
            Return to Top
            <span className="icon-up-arrow ml-1" />
          </Button>
        </div>
      </div>
    </StyledCardComparision>
  );
};

export const CardComparision = (props: CardComparisionProps) => {
  return (
    <EditableComponent config={CardComparisionConfig} {...props}>
      <CardComparisionComp {...props} />
    </EditableComponent>
  );
};
