/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC } from 'react';
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import clsx from 'clsx';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { TabComponentContainerProps } from './TabComponentContainer.types';
import { StyledTabComponentContainer } from './TabComponentContainer.styles';
import { TabComponent, respGridUtil } from '@marriott/mi-ui-library';
import { AEMReactCompMap, shouldCardDisplay } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';

export const TabCompConfig = {
  emptyLabel: 'TabContainer',
  isEmpty: () => true,
  resourceType: `/components/content/tabContainer`,
};

// Use named rather than default exports.
export const TabContainer: FC<TabComponentContainerProps> = (props: TabComponentContainerProps) => {
  const {
    cqItems,
    isAuthorMode,
    totalNumberOfChildComponents,
    allowedComponents,
    itemPath,
    pagePath,
    tabItems,
    columnLayout,
    componentId,
    additionalTrackingVariables,
    trackingDescription,
    impressionTrack,
    trackingContentPosition,
    atCCeVar48,
    clickTrack,
    merchandisingCategory,
    impressionCount,
    trackingTag,
    trackingOfferType,
    linkText,
    linkUrl,
    ctadescMobile,
    ctadescDesktop,
  } = props;

  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };

  const { cobrandData, setCobrandData } = useStore(store => store);
  const tabContainer = tabItems;
  const mapper = AEMReactCompMap(props?.allowedComponents);
  //Method returns list of Tabs to be displayed to be displayed
  const getTabList = () => {
    const tabList = tabContainer?.map(tabItem => {
      const tab = {
        ...tabItem,
        tabSubtitle: tabItem.tabDescription,
        tabValue: tabItem.tabTitle.toLowerCase(),
      };
      return tab;
    });
    return tabList?.length ? tabList : [];
  };

  //Method returns components to be displayed in the Authored Order
  const getItemsSorted = (Card: any) => {
    const Items = Object.entries(Card[':items']);
    const itemsOrder = Card[':itemsOrder'];
    return Object.fromEntries(Items.sort((a: any, b: any) => itemsOrder.indexOf(a[0]) - itemsOrder.indexOf(b[0])));
  };

  //Method for returning Responsive Grid for showing parsys in Author mode
  const cardContainer = (index: number) => {
    return (
      <div className={clsx('col-12')}>
        <ResponsiveGrid
          gridClassNames={`col-12`}
          columnClassNames={{
            responsivegrid: `col-12`,
          }}
          customClassName={`p-0 m-0`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pagePath={pagePath}
          itemPath={`${itemPath}/${totalNumberOfChildComponents[index]}`}
          config={{
            isEmpty: () => true,
            resourceType: 'mi-aem-common-spa/components/container',
          }}
        />
      </div>
    );
  };

  //Method for returning components to be rendering at end point URL
  const cardComponent = (cardName: string, jsonData: any, index: number) => {
    //eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(cardName)) {
      const card = jsonData[cardName];
      const cardItems: any = getItemsSorted(card);
      const returnable = [];
      for (const itemKey in cardItems) {
        if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
          const itemType = cardItems[itemKey][':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            if (!cardItems?.[itemKey]?.['cardType']) {
              if (innerComp === 'CardCarouselContainer') {
                cardItems[itemKey] = {
                  ...cardItems[itemKey],
                  cqItems: cardItems[itemKey][':items'],
                  allowedComponents: allowedComponents,
                  column: { columnLayout },
                  styleClass: 'mt-5 mt-lg-0',
                  model: {
                    ...cardItems[itemKey].model,
                    variation: 'standard',
                    cqType: 'mi-aem-common-spa/components/content/cardcarouselcontainer',
                  },
                };
              }
              if (tabItems) {
                const initialTab = tabItems[0].tabTitle?.toLowerCase();
                const reqJsonData = { ...jsonData[cardName] };
                reqJsonData[':items'] = { [itemKey]: jsonData[cardName]?.[':items']?.[itemKey] };
                if (itemKey === 'cardcarouselcontaine') {
                  reqJsonData[':items'][itemKey] = {
                    ...reqJsonData[':items']?.[itemKey],
                    trackingProperties: trackingProperties,
                    ctadescMobile: ctadescMobile,
                    linkText: linkText,
                    linkUrl: linkUrl,
                    ctadescDesktop: ctadescDesktop,
                  };
                }
                returnable.push(
                  <div
                    data-cardtype={initialTab}
                    key={index}
                    className={shouldCardDisplay(initialTab, cobrandData.activeTabValue) ? 'd-block' : 'd-none'}
                  >
                    <ResponsiveGrid model={respGridUtil(reqJsonData)} {...respGridUtil(reqJsonData)} />
                  </div>
                );
              }
            } else {
              const reqJsonData = { ...jsonData[cardName] };
              reqJsonData[':items'] = { [itemKey]: jsonData[cardName]?.[':items']?.[itemKey] };
              returnable.push(<ResponsiveGrid model={respGridUtil(reqJsonData)} {...respGridUtil(reqJsonData)} />);
            }
          }
        }
      }
      return <div className={clsx('col-12')}>{returnable}</div>;
    }
    return null;
  };

  return (
    <StyledTabComponentContainer
      data-testid="tabcomponentcontainer"
      data-component-name="o-cobrand-tabcomponentcontainer"
    >
      <div className={clsx('column-container-tabs')} id={componentId}>
        <TabComponent
          tabList={getTabList()}
          setSelectedTab={(activeTabValue: string) => {
            setCobrandData({
              activeTabValue,
              selectedCards: [],
            });
          }}
          ariaRole="note"
          customClass={''}
        />
        {isAuthorMode && Array.from({ length: columnLayout }, (_, i) => cardContainer(i))}
        {!isAuthorMode &&
          totalNumberOfChildComponents?.map((cardName: string, index: number) =>
            cardComponent(cardName, cqItems, index)
          )}
      </div>
    </StyledTabComponentContainer>
  );
};
export const TabComponentContainer = (props: TabComponentContainerProps) => {
  return (
    <EditableComponent config={TabCompConfig} {...props}>
      <TabContainer {...props} />
    </EditableComponent>
  );
};
